.Wrapper,
.Container {
    position: relative;
}

.Container {
    animation: fade-in ease .5s;
}

.PrevButton {
    position: absolute;
    top: 50%;
    right: 92.5%;
    transform: translateY(-42.5%);
    z-index: 1;
}

.NextButton {
    position: absolute;
    top: 50%;
    left: 92.5%;
    transform: translateY(-42.5%);
    z-index: 1;
}

.PrevButton button,
.NextButton button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 1;
    position: relative;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px 1px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    background: rgb(255, 255, 255);
}

.NextButton button {
    justify-content: flex-start;
}

.PrevButton button {
    justify-content: flex-end;
}

@media (min-width: 960px) {
    .PrevButton button,
    .NextButton button {
        width: 35px;
        height: 35px;
    }
}

.PrevButton button:after,
.NextButton button:after {
    position: absolute;
    top: 25%;
    right: 37.5%;
    display: block;
    content: '';
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    border: 2px solid #FF7043;
    border-radius: 12.5%;
    border-left: 0;
    border-bottom: 0;
}

.PrevButton button:after {
    left: 37.5%;
    transform: rotate(-135deg)
}

@media (min-width: 960px) { 
    .PrevButton button:after,
    .NextButton button:after {
        top: 25%;
        width: 15px;
        height: 15px;
    }
}

.PhotoContainer {
    display: block;
}

.Photo {
    padding: 2.5px;
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
    height: auto;
    background-color: #EEEEEE;
}

.SideNavWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    animation: fade-in ease 1s;
}

.SideNavContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
}

.SideNavContainer div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content !important;
}

.Thumbnail {
    width: 100%;
}

.ThumbnailContainer {
    padding: 4px 8px;
}

.Thumbnail img {
    position: relative;
    max-width: 92px !important;
    max-height: 69px !important;
    border-radius: 7.5px;
    box-shadow: 1px 1px 8px rgba(0,0,0,0.36), -1px -1px 6px rgba(0,0,0,0.43);
    transition: box-shadoww 0.5s cubic-bezier(.25,.8,.25,1);
    box-sizing: content-box;
    padding: 0;
    margin: 0;
}

.Thumbnail.active img {
    box-shadow: 1px 1px 10px rgba(30,163,204,1), -1px -1px 10px rgba(30,163,204,1);
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    66% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}