.Button {
    cursor: pointer;
    background-color: transparent;
    display: block;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
}

.Favorite,
.Favorite * {
    transition: fill ease 100ms;
}