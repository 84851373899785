.SearchBarAnchor {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.SearchBarWrapper {
    display: block;
    box-sizing: border-box;
}

.SearchBarContainer {
    display: table;
    overflow: hidden;
    width: 100%;
}

.BarWrapper {
    display: table-cell;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    transition: box-shadow 200ms ease-in, border 0.2s ease;
}

.BarWrapper:hover,
.BarWrapper:focus-within {
    border: 1px solid rgba(35, 189, 153);
}

.BarWrapper:hover .QuestionMark,
.BarWrapper:focus-within .QuestionMark {
    border-left: 1px solid rgba(35, 189, 153);
    background-color: rgba(35, 189, 153);
}

@media (min-width: 1024px) {
    .BarWrapper:hover .QuestionMark svg,
    .BarWrapper:focus-within .QuestionMark svg {
        fill: #FFF !important;
    }
}

.BarContainer {
    width: 100%;
    height: 100%;
    padding-left: 12px;
}

.LabelWrapper {
    display: table;
    margin: 0px;
    margin-bottom: -1px;
    margin-right: -1px;
    padding: 0px;
    table-layout: fixed;
    white-space: nowrap;
}

.SearchBarSpan {
    position: absolute;
    display: block;
    border: 0px;
    margin: -1px;
    padding: 0px;
    height: 1px;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
}

.QuestionMark {
    display: table-cell;
    box-sizing: border-box;
    border-left: 1px solid rgba(26, 26, 29, 0.08);
    cursor: pointer;
    vertical-align: middle;
    padding: 4.5px;
    width: 39px;
    height: 39px;
    transition: all 0.2s ease;
}

.QuestionMark svg {
    height: 18px !important;
    width: 18px !important;
    margin: 0 auto;
    fill: #484848 !important;
    transition: fill 0.2s ease;
}

.InputWrapper {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    width: 100%;
    padding-right: 12px;
}

.InputContainer {
    overflow: hidden;
    position: relative;
}

.Input {
    background-color: transparent;
    border: 0px;
    margin: 0px;
    width: 100%;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 17px;
    padding: 4px 0;
    line-height: 20px;
    bottom: 0;
}

.InputSelect {
    border-radius: 4px;
    color: #484848;
    border: 1px solid #EBEBEB;
    transition: box-shadow 200ms ease-in, border 0.2s ease;
}

.InputSelect option {
    color: #484848;
}

.InputSelect:hover,
.InputSelect:focus-within {
    border: 1px solid rgba(35, 189, 153);
}