.GlobalWrapper {
    position: relative;
    height: 56px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-radius: 4px;
    transition: border-color 0.15s ease-out;
}

.GlobalWrapper:focus-within, 
.GlobalWrapper:hover{
    border: 1px solid #FF7043;
}

.Wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.SearchBarAnchor {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

@media (max-width: 1121px) {
	.SearchBarAnchor {
        justify-content: flex-start;
        width: 100%;
	}
}

.SearchBarWrapper {
    display: block;
    position: absolute;
    box-sizing: border-box;
    top: auto;
    transition: width 200ms ease-in;
    width: 100%;
    height: 100%;
}

.SearchBarContainer {
    overflow: hidden;
    width: 100%;
    display: table;
    height: 100%;
    
}

@media (max-width: 1121px) {
    .SearchBarContainer {
        transition: transform 300ms ease-out;
        display: flex;
        z-index: 210;
    }
    /* When Focused */
    .SearchBarContainer.FocusWithin {
        transform: translateX(10px);
    }
    .SearchBarContainer.FocusWithin .QuestionMark {
        display: none;
    }
    .SearchBarContainer.FocusWithin .Cancel {
        visibility: visible;
        width: auto;
        opacity: 1;
    }
}
.Bar {
    background-color: inherit;
    border: 0;
    border-radius: 4px;
}

@media (min-width: 1120px) {
    .Bar:hover {
        box-shadow: 0 2px 4px rgba(0,0,0,0.1), 0 4px 12px rgba(26, 26, 29, 0.08);
    }
}

@media (min-width: 1120px) {
    .Bar {
        display: table-cell;
        width: 100%;
        vertical-align: middle;
    }
}

@media (max-width: 1121px) {
    .Bar {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }
}

.WidescreenContainer {
    width: 100%;
    height: 100%;
    padding-left: 12px;
    padding-right: 4px;
}

.LabelWrapper {
    display: table;
    margin: 0px;
    padding: 0px;
    table-layout: fixed;
    white-space: nowrap;
    width: 100%;
}

.SearchBarSpan {
    position: absolute;
    display: block;
    border: 0px;
    margin: -1px;
    padding: 0px;
    height: 1px;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
}

.QuestionMark {
    width: 32px;
    display: table-cell;
    height: 100%;
    vertical-align: middle;
}

.InputWrapper {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    width: 100%;
}

.InputContainer {
    overflow: hidden;
    position: relative;
}

.Input {
    background-color: transparent;
    border: 0px;
    margin: 0px;
    padding: 0px;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 17px;
}

.Cancel {
    width: 0;
}

@media (min-width: 1120px) {
    .Cancel {
        display: none;
    }
}

@media (max-width: 1121px) {
    .Cancel {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        height: 100%;
        justify-content: flex-end;
        opacity: 0;
        margin-left: 8px;
        transition: all 300ms ease-in 200ms;
        visibility: hidden;
    }
    .Cancel:before {
        width: 12px;
        content: " ";
        display: block;
    }
}

.CancelButton {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: normal;
    color: #484848;
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    margin: 0px;
    margin-right: 24px;
    padding: 4px;
    text-decoration: none;
    width: auto;
    min-width: 48px;
}

.List {
    background-color: rgb(255,255,255);
    border: 1px solid #EBEBEB;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    box-shadow: 0;
    margin: 0px;
    padding: 0px;
    z-index: 10;
    opacity: 0;
    animation: FadeIn ease 350ms;
    position: absolute;
    width: 100% !important;
    top: 100% !important;
}

.RecentSearchesWrapper {
    opacity: 0;
    transition: opacity 300ms linear 266ms;
    border-top: 1px solid #EBEBEB;
}

.RecentSearchesContainer {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.RecentSearches {
    margin: 16px 24px;
}

.RecentSearches small {
    overflow-wrap: break-word;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: normal;
    color: rgb(72, 72, 72);
    font-weight: 600;
    margin: 0px;
}

.RecentSearches span {
    text-transform: uppercase;
}

.SearchResultsWrapper {
    margin: 0px 0px 16px;
    padding: 0px;
}

.Show {
    background-color: rgba(255,255,255,1);
    opacity: 1;
    overflow: visible;
    visibility: visible;
}

.List.Show {
    box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
}

@keyframes FadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}