.ScrollToTopButton {
    box-sizing: content-box;
    z-index: 5;
    position: fixed;
    cursor: pointer;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 35%;
    color: #fff;
    background-color: transparent;
    background-image: none;
    border: 2px solid rgb(255, 112, 67);
    transition: all 0.25s ease-out;
    opacity: 0;
    animation: fadeIn 0.25s forwards;
}

.ScrollToTopButton span {
    display: block;
    box-sizing: content-box;
    width: 50%;
    height: 50%;
    border-left: 2px solid rgb(255, 112, 67);
    transform: rotate(45deg) translate(87.5%, 25%);
}

.ScrollToTopButton span:before {
    content: '';
    display: block;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    border-left: 2px solid rgb(255, 112, 67);
    transform: rotate(90deg) translate(5%, 15%);
}

.ScrollToTopButton:hover {
    color: #fff;
    background-color: rgb(255, 112, 67);
    border-color: #fff;
}

.ScrollToTopButton:hover span,
.ScrollToTopButton:hover span:before {
    border-left: 2px solid #fff;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}