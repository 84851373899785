.Wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.Image {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
    border: 0;
    box-sizing: border-box;
}