.Input {
    width: 100%;
    padding: 10px 0;
    margin: 15px 0;
    box-sizing: border-box;
    position: relative;
}

.Label {
    color: #999; 
    font-size: 17px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 10px;
    /* top:20px; */
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s ease all; 
}

.TextAreaLabel {
    top: 25px;
    transform: translateY(-15px);
}

.Input input:focus ~ .Label, .Input input:valid ~ .Label,
.Input textarea:focus ~ .Label, .Input textarea:valid ~ .Label {
    top: -7.5px;
    left: 5px;
    font-size: 14px;
    color: #484848;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.InputElement {
    border-radius: 5px;
    border: 1px solid #EBEBEB;
    background-color: #fff;
    font: inherit;
    font-size: 17px;
    padding: 10px;
    display: block;
    width: 100%;
    height: 54px;
    box-sizing: border-box;
    transition: all 0.2s ease; 
}

.TextAreaElement {
    min-width: 100%;
    max-width: 100%;
    min-height: 125px;
}

textarea.InputElement::-webkit-input-placeholder,
input.InputElement::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: transparent;
}

textarea.InputElement::-moz-placeholder,
input.InputElement::-moz-placeholder { /* Firefox 19+ */
    color: transparent;
}

textarea.InputElement:-ms-input-placeholder,
input.InputElement:-ms-input-placeholder { /* IE 10+ */
    color: transparent;
}

textarea.InputElement:-moz-placeholder,
input.InputElement:-moz-placeholder { /* Firefox 18- */
    color: transparent;
}

textarea:invalid {
    box-shadow: none;
}

textarea:-moz-submit-invalid {
    box-shadow: none;
}

textarea:-moz-ui-invalid {
    box-shadow:none;
}

.InputElement:focus {
    outline: none;
    border-color: #1ea3cc;
}

.Bar  { 
    position: relative; 
    display: block; 
    width: 100%; 
}

.Bar:before, .Bar:after   {
    content: '';
    height: 1.5px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: #1ea3cc;
    transition: 0.2s ease all;
}
.Bar:before {
    left: 50%;
}
.Bar:after {
    right: 50%; 
}

.Input input:focus ~ .Bar:before, .Input input:focus ~ .Bar:after,
.Input textarea:focus ~ .Bar:before, .Input textarea:focus ~ .Bar:after {
    width: calc(50% - 2.5px);
}

.Invalid {
    border-color: #dc3545;
}

.InvalidFeedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
    position: absolute;
    left: 0;
    text-align: right;
}

.Valid {
    border-color: #28a745;
}

.ValidFeedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #28a745;
    position: absolute;
    left: 0;
    text-align: right;
}

/* Input Select */

.InputSelectLabel {
    display: none;
}