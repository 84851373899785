.Button {
    position: relative;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    width: fit-content;
    user-select: none;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: .25rem;;
    font-weight: var(--font-bold-font-weight, 600);
    padding-top: var(--spacing-button-vertical, 10px);
    padding-bottom: var(--spacing-button-vertical, 10px);
    padding-left: var(--spacing-button-horizontal, 17px);
    padding-right: var(--spacing-button-horizontal, 17px);
    box-shadow: var(--shadow-button-level0-box-shadow, none);
    transition: background-color .1s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out, opacity 0.15s;
}

.Logo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: auto 10px;
}

.BlockButton {
    width: 100%;
}

.Button:disabled {
    opacity: 0.45;
    cursor: not-allowed;
}

.Success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745;
}

.Success:not([disabled]):hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.Danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545;
}

.Danger:not([disabled]):hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.Primary {
    color: #FF7043;
    background-color: transparent;
    background-image: none;
    border-color: #FF7043;
}

.Primary:not([disabled]):hover {
    color: #fff;
    background-color: #FF7043;
    border-color: #FF7043;
}

.Default {
    color: #484848;
    border: 1px solid #EAEAEA;
    background: #FFF;
    background-image: none;
}

.Default:not([disabled]):hover {
    color: #fff;
    background-color: #484848;
    border-color: #484848;
}

.Facebook {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    color: rgb(255, 255, 255);
    background: rgb(59, 89, 152);
    border-color: transparent;
}

.Google {
    display: flex;
    justify-content: center;
    align-items: center;    
    margin-bottom: 12px;
    color: rgb(255, 255, 255);
    background: rgb(219, 50, 54);
    border-color: transparent;
}

.Auth {
    margin: 12px 0;
    color: rgb(255, 255, 255);
    background: rgb(255, 112, 67);
    border-color: transparent;
}

.Playstore,
.Applestore {
    display: table;
    justify-content: center;
    align-items: center;    
    padding-left: 0;
    color: rgb(255, 255, 255);
    background: #040707;
    border-color: transparent;
    min-width: 200px;
}

.Playstore:hover,
.Applestore:hover {
    color: #333;
    background: #FFF;
    border-color: #333;
    transition: all ease 200ms;
}

.Applestore svg path {
    transition: fill ease 200ms;
}

.Applestore:hover svg path {
    fill: #333 !important;
}

.Appstore {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
}

.AppLogo {
    display: table-cell;
    vertical-align: bottom;
    padding: 0 12px 0 6px;
}

.Download {
    font-size: .75rem;
    text-transform: uppercase;
}

.Store {
    font-size: 1.5rem;
    font-weight: normal;
}