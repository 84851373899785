.Container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(20%, 1fr) );
    grid-auto-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
}

.Container h1 {
    color: rgb(103, 88, 88);
    user-select: none;
    margin-bottom: 40px;
}

@media (max-width: 1080px) {
    .Container {
        grid-template-columns: repeat( auto-fit, minmax(25%, 1fr) );
    }
}

@media (max-width: 744px) {
    .Container {
        grid-template-columns: repeat( auto-fit, minmax(33.33%, 1fr) );
    }
}

@media (max-width: 360px) {
    .Container {
        grid-template-columns: repeat( auto-fit, minmax(50%, 1fr) );
    }
}

.Service {
    margin: 5px auto;
    width: 100%;
    max-width: 360px;
}

.NotFound {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-height: calc(100vh - 70px);
    margin: 0 auto;
}

@media (max-width: 1206px) {
    .NotFound {
        flex-flow: column !important;
    }
    .Image {
        margin: 0 12px !important;
        min-width: 280px !important;
        height: 280px !important;
    }
}

@media (max-width: 744px) {
    .Image {
        min-width: 240px !important;
        height: 240px !important;
    }
}

@media (max-width: 520px) {
    .Image {
        margin: 24px 12px !important;
        min-width: 220px !important;
        height: 220px !important;
    }
}

.Image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin: 0 36px;
    width: 29.666%;
    min-width: 380px;
    height: 600px;
}

.Image img {
    object-fit: contain;
}