.HeaderImage {
    background-color: #555;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: inset 0px 40px 220px -29px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px 40px 220px -29px rgba(0,0,0,0.75);
    box-shadow: inset 0px 40px 220px -29px rgba(0,0,0,0.75);
}

.Background {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
    border: 0;
    border-radius: 5px;
    box-sizing: border-box;
}

.FadeIn {
    animation: fadein .25s ease-out 0s forwards;
}

@keyframes fadein {
    from { 
        opacity: 0; 
    }
    to   { 
        opacity: 1; 
    }
}