.Wrapper {
    display: block;
    width: 100%;
    max-width: 100vw !important;
    height: 100%;
}

.Container {
    position: sticky;
    max-width: 100%;
    top: 96px;
    margin-left: 24px;
    margin-right: 24px;
}

@media (max-width: 744px) {
    .Wrapper {
        width: 100% !important;
        margin: 0 auto;
        padding: 0 0 25px !important;
        margin-bottom: 25px !important;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #EBEBEB;
        border-bottom-width: var(--border-rule-border-width, 1px);
        border-bottom-color: var(--color-divider, #EBEBEB);
    }
}