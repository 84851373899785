.LandingNavbar {
    height: 70px;
    width: 100%;
    position: absolute;
    overflow: visible;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255) !important;
    box-shadow: inset 0px 0px 169px 6px rgba(0,0,0,0.4);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    transition: background-color 0.25s ease-out;
	z-index: 25;
}

.PublishNavbar {
    position: relative;
    height: 70px;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
    background: rgb(31, 165, 202) !important;
	box-sizing: border-box;
	z-index: 25;
}

.EditPublicationNavbar {
    position: relative;
    height: 70px;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
    background: rgb(118, 113, 198) !important;
	box-sizing: border-box;
	z-index: 25;
}

.SupportNavbar {
    position: relative;
    height: 70px;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
    background: rgb(35, 189, 153) !important;
	border-bottom: 0.5px solid rgba(51,51,51,0.15);
	box-sizing: border-box;
	z-index: 25;
}

.Navbar {
    position: relative;
    height: 70px;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
    background-color: rgba(255,255,255) !important;
	border-bottom: 0.5px solid rgba(51,51,51,0.15);
	box-sizing: border-box;
	z-index: 25;
}

.NavbarTransparent {
    border-bottom: transparent !important;
    background-color: rgba(0,0,0,0) !important;
    padding-bottom: 4px;
}

.NavbarContainer {
    display: flex;
    height: 100%;
    position: relative;
}

.Logo {
    height: 85%;
}

@media screen and (max-width: 1121px) {
    .NavbarContainer {
        order: 2;
        justify-content: flex-end;
        margin-right: 15px;
    }
}