.Container {
    margin: 0 auto;
    background-color: transparent;
    padding: 0 12px;
    max-width: 1240px;
    min-height: 400px;
}

.Auth {
    margin: 42px auto;
    padding: 24px 9px;
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    border: 1px solid #dce0e0;
    min-height: calc(100vh - 162px);
    max-width: 480px;
    align-items: center;
    justify-content: flex-start;
}

.Loading {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}


.Error,
.Success {
    overflow-wrap: break-word;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1em;
    margin: 0 0 36px;
}

.Error {
    color: #dc3545;
}

.Success {
    color: #ff7043;
    font-weight: normal;
}

@media (max-width: 520px) {
    .Auth {
        margin: 24px 0;
    }
}

.Auth > * {
    width: 100%;
}

.Header {
    color: #484848;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

.Subheader {
    color: #484848;
    margin: 12px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}