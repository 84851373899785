.SeparatorWrapper {
    user-select: none;
}

.SeparatorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.Line {
    position: absolute;
    width: 100%;
    height: 1px;
    background: rgba(72,72,72,0.15);
}