.Wrapper {
    margin-right: 6px;
}

.Toggle {
    cursor: pointer;
    background-color: transparent;
    display: block;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
}

.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
}

.Button {
    margin: 6px 12px;
    cursor: pointer;
}

.Header {
    color: #484848;
    user-select: none;
    text-align: center;
}