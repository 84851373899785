.ResultWrapper {
    cursor: pointer;
    display: table;
    height: 60px;
    list-style-type: none;
    min-height: 60px;
    width: 100%;
    padding: 12px 24px;
}

.ResultWrapper:hover {
    background-color: rgba(72,72,72,.15);
}

.Icon {
    color: rgb(72, 72, 72);
    display: table-cell;
    padding-right: 12px;
    /* padding-top: 4px; */
    vertical-align: middle;
}

.Icon span {
    font-weight: normal;
}

.ResultContainer {
    display: table-cell;
    vertical-align: top;
    width: 100%;
}

.Result,
.Service,
.Location {
    font-weight: 600;
    overflow-wrap: break-word;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: normal;
    color: #484848;
    margin: 0px;
}

.Result {
    font-weight: normal;
}

.Location {
    color: #484848;
    font-weight: normal;
}

.NullResult {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}