.Wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    background-color: transparent;
    padding: 0 24px 18px;
}

.Header, 
.Container {
    display: flex;
    flex-flow: row;
    max-width: 1220px;
    width: 100%;
    background-color: transparent;
    padding: 0 24px 18px;
}

@media (max-width: 744px) {
    .Wrapper,
    .Header {
        flex-flow: column;
    }
}

.Container {
    flex-flow: column;
}

.Photo {
    flex: 0 0 25%;
    padding: 20px 12px;
}

@media (max-width: 480px) {
    .Header {
        padding: 4px 8px;
    }
    .Container {
        padding: 4px 8px;
    }
}

.Container h1 {
    font-size: 32px;
    font-weight: 600;
    color: #484848;
    user-select: none;
    margin-bottom: 26px;
}

.Content {
    flex: auto;
    display: flex;
    flex-flow: column;
    padding: 20px 12px;
    margin: 0 36px;
}

@media (max-width: 480px) {
    .Content {
        margin: 0;
    }
}

.Title {
    user-select: none;
    color: #484848;
    margin: 6px 0;
    font-size: 62px;
    font-weight: normal;
}

.JoinDate {
    user-select: none;
    color: #484848;
    margin: 6px 0;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.1;
}