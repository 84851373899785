.Container {
    margin-bottom: 4px;
}

.Title {
    font-weight: 600;
    margin: 0px;
    word-wrap: break-word;
    font-size: 46px;
    line-height: 52px;
    letter-spacing: normal;
    color: #484848;
    padding-top: 8px;
    padding-bottom: 8px;
}

@media (max-width: 744px) {
    .Title {
        font-size: 24px;
        line-height: 26px;
}
}

.Title h1 {
    color: inherit;
    font-size: 1em;
    font-weight: inherit;
    line-height: inherit;
    margin: 0px;
    padding: 0px;
}