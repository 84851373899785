.ImageWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.Loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Image {
    left: 0;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
    border: 0;
    box-sizing: border-box;
}