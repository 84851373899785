.NavbarLogo img {
    user-select: none;
    width: 34px;
    border-radius: 10px;
    display: flex;
}

.Spacing {
    flex-grow: 1;
}

.SpacingHalf{
    flex-grow: 0.5;
}

.SpacingSmall {
    flex-grow: 0.1;
}

.Navtext{
    color: #484848;
    margin-right: 10px;
}

.NavigationItems {
    user-select: none;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.MobileHomeLink,
.CloseToggle,
.ArrowWrapper,
.Arrow, {
    display: none;
}

@media (min-width: 1120px) {
    .NavigationItems {
        width: calc(100vw - 30px);
        margin: 0;
        flex-flow: row;
    }
    .NavbarSpacing {
        display: none;
    }
}

@media (max-width: 1121px) {
    .DesktopOnly {
        display: none !important;
    }
    .MobileLogo {
        display: flex;
        width: 100%;
        padding: 42px 23px 12px;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }
    .MobileLogo img {
        user-select: none;
        display: flex;
        width: 96px;
        border-radius: 25%;
    }
    .CloseToggle,
    .MobileHomeLink {
        display: flex;
        width: 150px;
        width: fit-content;
        margin-top: 25px;
        padding: 10px 22.5px;
        max-width: 150px;
        align-items: center;
        justify-content: center;
        color: #484848;
        font-size: 16px;
        letter-spacing: normal;
        font-weight: normal;
        text-decoration: none;
    }
    .CloseToggle {
        margin-top: 0px;
        margin: 0 12px;
        border: 1px solid rgb(209, 209, 209);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        padding: 0;
        padding-right: 13px;
    }
    .ArrowWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        padding: 13px;
    }
    .Arrow {
        width: 100%;
        height: 100%;
        border-top: 2px solid #686868;
        border-right: 2px solid #686868;
        transform: rotate(-45deg)
    }
}