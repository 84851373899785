body {
  margin: 0;
  padding: 0;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Carousels */
.slick-track {
  margin-left: 0 !important;
}

/* Common */

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button, input {
  line-height: normal;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

textarea:focus, input:focus{
  outline: none;
}

[type="text"], [type="password"], [type="search"], [type="email"], [type="url"], [type="number"], [type="tel"], textarea, select, .input-prefix, .input-suffix {
  display: block;
  padding: 8px 10px;
  width: 100%;
}

[type="text"], [type="password"], [type="search"], [type="email"], [type="url"], [type="number"], [type="tel"], textarea, select, input[type="radio"], input[type="checkbox"] {
  transition: border-color 0.2s;
}

[type="text"], [type="password"], [type="search"], [type="email"], [type="url"], [type="number"], [type="tel"], textarea, select, input[type="radio"], input[type="checkbox"], .input-prefix, .input-suffix {
  border: 1px solid #aaa;
  border-radius: 2px;
  background-color: #fff;
  color: #484848;
}

input[type="radio"], input[type="checkbox"] {
  position: relative;
  appearance: none;
  height: 1.25em;
  width: 1.25em;
  margin-bottom: -0.25em;
  margin-right: 5px;
  vertical-align: top;
}

*, *:before, *:after {
  box-sizing: border-box;
  flex: 0 1 auto;
  -ms-flex: 0 1 auto;
}

label {
  display: block;
  padding-top: 9px;
  padding-bottom: 8px;
}

label, input, textarea, select, input[type="radio"], input[type="checkbox"], .input-prefix, .input-suffix {
  line-height: normal;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}