
.Wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 24px;
}

.Container {
    position: sticky;
    top: 24px;
    left: 0;
}

.Container {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: sticky;
}

.Photo {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    border: 1px solid #E0E0E0;
    background-color: #EDEFED;
    object-fit: cover;
}

@media (max-width: 744px) {
    .Wrapper {
        flex: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }
    .Container {
        width: 100%;
        padding-top: 100%; /* 1:1 Aspect Ratio */
        position: relative;
        top: 0;
    }
    .Photo img {
        object-fit: cover;
    }
}