.Wrapper {
    margin-top: 24px;
    position: relative;
    height: 390px;
    border-radius: 10px;
    overflow: visible;
    box-sizing: border-box;
    user-select: none;
}

.BackgroundWrapper {
    position: absolute;
    height: 390px;
    width: 100%;
    box-sizing: border-box;
    background-color: #1b2d37;
}

.BackgroundWrapper img {
    object-fit: cover;
}

.Background {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
    border: 0;
    border-radius: 5px;
    box-sizing: border-box;
}

.BannerWrapper {
    display: block;
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    user-select: none;
    background-color: rgba(0,0,0,.16);
}

.BannerContainer {
    display: flex;
    flex-flow: column;
    box-sizing: border-box;
    position: absolute;
    width: auto;
    height: 85%;
    margin-top: 32px;
    margin-left: 32px;
    margin-right: 32px;
}

@media (min-width: 744px) {
    .BannerContainer {
        margin-left: 48px;
        margin-right: 48px;
    }
}

.Offer {
    font-weight: normal;
    margin-bottom: 20px;
    color: rgb(255, 255, 255);
    max-width: 300px;
    font-size: 23px;
    line-height: 29px;
}

.Offer span {
    font-weight: 800;
    margin-bottom: auto;
}

@media (min-width: 940px) {
    .Offer {
        font-size: 32px;
        line-height: 44px;
    }
}

@media (min-width: 744px) {
    .Offer {
        max-width: 50%;
        font-size: 30px;
        line-height: 36px;
    }
}

.ButtonWrapper {
    margin-top: auto;
    margin-bottom: 16px;
}

.Tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    width: calc(20px + 0.4em);
    height: calc(20px + 0.4em);
    border: 1px solid #FFF;
    color: #FFF;
    cursor: pointer;
    border-radius: 50%;
    left: 0.4em;
    bottom: 0.4em;
}