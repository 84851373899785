.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-height: calc(100vh - 70px);
    margin: 0 auto;
}

@media (max-width: 944px) {
    .Image {
        margin: 0 12px !important;
        min-width: 280px !important;
        height: 280px !important;
    }
    .Header {
        margin: 0 !important;
        font-size: 49px !important;
    }
    .Subheader {
        margin: 12px 0 36px !important;
        font-size: 21px !important;
    }
    .ErrorCode {
        margin: 0 0 6px !important;
    }
}

@media (max-width: 744px) {
    .Image {
        min-width: 240px !important;
        height: 240px !important;
    }
    .Header {
        font-size: 36px !important;
    }
    .Subheader {
        margin: 12px 0 32px !important;
        font-size: 16px !important;
    }
}

@media (max-width: 580px) {
    .Container {
        flex-flow: column !important;
    }
    .Image {
        margin: 24px 12px !important;
        min-width: 220px !important;
        height: 220px !important;
    }
    .Header {
        margin: 0 0 15px;
        font-size: 24px !important;
    }
    .Subheader {
        margin: 25px 0 15px;
        font-size: 16px !important;
    }
    .Links {
        margin: 6px 0 8px !important;
    }
}

.Image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin: 0 36px;
    width: 29.666%;
    min-width: 380px;
    height: 600px;
}

.Image img {
    object-fit: contain;
}

.Text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: column;
    margin: 0 36px;
    padding: 0 12px;
    height: 100%;
}

.Header {
    user-select: none;
    margin: 0 0 15px;
    font-size: 88px;
    line-height: 1.1;
    text-rendering: optimizelegibility;
    font-weight: 700;
    color: #484848;
}

.Subheader {
    user-select: none;
    margin: 25px 0 15px;
    font-size: 32px;
    line-height: 1.1;
    text-rendering: optimizelegibility;
    font-weight: normal;
    color: #484848;
}

.ErrorCode {
    margin: 24px 0 12px;
    font-size: 14px;
    line-height: 1.1;
    text-rendering: optimizelegibility;
    font-weight: 700;
    color: #686868;
}

.SiteMap {
    list-style: none;
    padding: 0;
    margin: 0 0 16px;
}

.Links {
    font-size: 14px;
    text-rendering: optimizelegibility;
    font-weight: normal;
    color: #484848;
}

.SiteMap li {
    margin: 3px 0;
    font-size: 15px;
}

.SiteMap a {
    margin: 0;
    color: #ff7043;
    text-decoration: none;
}