.SeparatorWrapper {
    user-select: none;
}

.SeparatorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.Line {
    position: absolute;
    width: 100%;
    height: 1px;
    background: rgba(72,72,72,0.15);
}

.Option {
    user-select: none;
    background: rgba(255,255,255);
    color: #484848;
    position: relative;
    padding-bottom: 16px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
}