.Service {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column;
    width: 100%;
    min-width: 120px;
    height: 100%;
    padding-top: 8px;
    padding-bottom: 12px;
}

.Wrapper {
    user-select: none;
    width: 100%;
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    position: relative;
    display: block;
    color: rgb(72, 72, 72);
    text-decoration: none;
    font-family: inherit;
}

.ThumbnailWrapper {
    user-select: none;
    padding-top: 66.6667%;
    background-size: 100% 100%;
    /* Google Chrome */
    contain: strict;
    position: relative;
    width: 100%;
    z-index: 0;
    border-radius: 3px;
    overflow: hidden;
}

.ThumbnailContainer {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
}

.Thumbnail {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    background-color: rgb(229, 229, 229);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    animation-name: FadeIn;
    animation-duration: 300ms;
    animation-timing-function: ease-out;
}

.Details {
    text-decoration: none;
    padding: 8px;
    display: block;
    color: rgb(72, 72, 72);
    font-weight: inherit;
    width: 100%;
}

.Header {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: normal;
    font-family: inherit;
    text-transform: uppercase;
    color: rgb(255, 112, 67);
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 2px;
    overflow: hidden;
}

.Title {
    display: block;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    max-height: 42px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 4px;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
}

.Price {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
    letter-spacing: normal;
    font-family: inherit;
    color: rgb(72, 72, 72);
}

.RatingsWrapper {
    display: flex;
    align-items: center;
}

.RatingsWrapper svg {
    width: 9px;
}

.RatingsAvg {
    overflow-wrap: break-word;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: normal;
    font-family: inherit;
    color: rgb(72, 72, 72);
    font-weight: 800;
    display: inline;
    margin: 0px;
}

.RatingsAmount {
    display: block;
    line-height: 21px;
}

.Amount {
    font-weight: 600;
    overflow-wrap: break-word;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: normal;
    font-family: inherit;
    color: rgb(72, 72, 72);
    display: inline;
    margin: 0px;
}