.QuestionContainer {
    list-style: none;
    padding: 0;
    width: 95%;
    margin: 48px 0;
    border-radius: 15px;
    border: 1px solid rgba(78, 78, 78, .25);
    transition: border ease 100ms, box-shadow ease 300ms,
}

@media (max-width: 900px) {
    .QuestionContainer {
        width: 100%;
    }
}

.QuestionContainer:hover {
    border: 1px solid rgba(35, 189, 153, .5);
}

.QuestionContainer.Open {
    border: 1px solid rgba(35, 189, 153, 1);
}

.Question {
    user-select: none;
    position: relative;
    display: block;
    cursor: pointer;
    text-align: left;
    font-size: 24px;
    font-weight: 300;
    margin: 0;
    padding: 24px 72px 24px 24px;
    color: #484848;
}

@media (max-width: 744px) {
    .Question {
        font-size: 18px;
        padding: 24px 56px 24px 24px;
    }
}

/* arrow icon on the right */
.Question:before, .Question:after {
    position: absolute;
    content: '';
    right: 24px;
    top: 50%;
    height: 2px;
    width: 13px;
    background: rgb(35, 189, 153);
    transition: transform ease 150ms;
}

.QuestionContainer.Open .Question:before {
    transform: rotate(-45deg);
}

.QuestionContainer.Open .Question:after {
    transform: rotate(45deg);
}

.Question:before {
    transform: rotate(45deg);
    right: 32px;
}

.Question:after {
    transform: rotate(-45deg);
}

.Answer {
    color: #787878;
    font-size: 16px;
    padding: 0px 24px 0px 24px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 250ms linear,
}

@media (max-width: 600px), (max-height:350px) {
    .Answer {
        overflow: auto;
    }
}

.Answer.Open { 
    max-height: 350px;
}