.Wrapper {
    position: sticky;
    top: 0;
    left: 0;
    overflow-x: hidden;
    max-height: 100vh;
    width: 300px;
    transform: translateZ(0px);
    vertical-align: top;
    z-index: 1;
    overflow: auto overlay;
}

.Container {
    width: 100%;
    padding: 12px 24px 96px;
}

@media (max-width: 920px) {
    .Wrapper {
        order: 2;
        flex-basis: 0px;
        flex-basis: 0px;
        flex-grow: 0;
        flex-shrink: 0;
        border-left: 1px solid rgb(229, 229, 229);
        transition: flex-basis ease .2s;
    }
    .Wrapper.Open {
        flex-basis: 300px;
    }
    .Container {
        padding: 48px 24px;
    }
}