.ForgotPasswordContainer {
    display: flex;
    align-content: center;
    justify-content: center;
}

.ForgotPassword {
    cursor: pointer;
    color: #1ea5c8;
    appearance: none;
    user-select: auto;
    text-align: left;
    text-decoration: none;
    background: transparent;
    border: none;
    margin: 0px;
    margin-left: 8px;
    margin-right: 8px;
    margin: 5px auto;
    padding: 0px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
}

.ForgotPassword:hover {
    color: #1ea5c8;
    text-decoration: underline;
}