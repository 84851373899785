.Wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    left: 0;
    top: 0;
    z-index: 220;
    background-color: rgb(255, 255, 255);
    padding: 0 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease;
    border-left: 0;
    border-bottom: 0.5px solid rgba(51,51,51,0.5);
}

.Container {
    position: relative;
    height: 100%;
    padding: 18px 24px;
}

.Anchor {
    position: relative;
}

.Anchor ul {
    width: 100%;
    height: auto;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 48px;
}

.Anchor ul li *:not(hr) {
    color: #484848;
    justify-content: flex-start;
    font-size: 16px;
    letter-spacing: normal;
    padding: 8px 0;
    font-weight: normal;
    display: block;
    position: relative;
    text-decoration: none;
}

.Anchor ul li *:hover {
    color: #484848;
    border: 0;
    box-shadow: none;
}

.Anchor button,
.Anchor li {
    width: auto;
    height: auto;
    max-width: 100%;
}

.Open {
    transform: translateY(0);
}

.Open .Anchor {
    animation: FadeIn 0.5s ease;
}

.Close {
    transform: translateY(-100%);
}

.Close .Anchor {
    animation: FadeOut 0.25s ease;
}

@media (min-width: 1121px) {
    .Wrapper {
        display: none;
    }
}

@keyframes FadeIn {
    0% {
        opacity: 0;
    }
    66% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes FadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}