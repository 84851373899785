.ReasonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    max-width: 200px;
    float: left;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    transition: border ease 100ms;
}

.ReasonContainer:hover {
    border-color: rgb(255, 112, 67);
}

.Reason {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 24px 16px;
    overflow: hidden;
}

.Description {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 24px 0 0;
    color: rgb(72, 72, 72);
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}