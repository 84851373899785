.Footer {
    padding: 30px 0;
}

.FooterWrapper {
    user-select: none;
    position: relative;
    box-sizing: border-box;
    padding: 0 0 30px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    color:inherit;
    margin-top: 20px;
    z-index: 0;
}

.BannerWrapper {
    color: rgb(255, 112, 67) ;
    display: block;
    position: relative;
}

.BannerWrapper::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-box-shadow: inset 0 0 0 1px rgb(229, 229, 229);
    box-shadow: inset 0 0 0 1px rgb(229, 229, 229);
    display: block;
    pointer-events: none;
    z-index: 1;
}

.BannerBackground {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(245, 246, 248);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-size: 200%;
}

.BannerBackground:before,
.BannerBackground:after {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    display: block;
    position: relative;
}

.BannerContainer {
    padding: 20px;
    position: relative;
    margin: 0 auto;
    max-width: 1600px;
    z-index: 0;
}

.SocialMedia {
    text-align: center;
}

@media (min-width: 720px) {
    .SocialMedia {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }
}

.FollowUs {
    user-select: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.5em;
    padding: 10px 0;
}

@media (min-width: 720px) {
    .FollowUs {
        font-size: 1.4rem;
    }
}

@media (min-width: 720px) {
    .MediaLinks {
        display: table-cell;
        height: 100%;
        padding: 0 20px;
    }
}

.MediaLink {
    color: #FFF;
    padding: 0 10px;
    display: inline-block;
    text-decoration: none;
}

.MediaLink svg path {
    transition: fill ease-out 0.1s;
}

.MediaLink:hover svg path {
    fill: rgb(72,72,72);
}

.LogoWrapper {
    margin: 30px 0;
    box-sizing: border-box;
    vertical-align: baseline;
}

.Logo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 124px;
    height: 124px;
    background-color: #EEEEEE;
    border-radius: 30px;
    margin: 0 auto;
    text-decoration: none;
}

.NavLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}

.NavLinks .NavLink:nth-child(n+2):before {
    content: '';
    display: inline-block;
    height: 12px;
    width: 1px;
    background-color: rgba(35,35,35,0.4);
    margin: 0 15px;
    pointer-events: none;
    user-select: none;
    cursor: none;
}

.NavLink {
    font-size: inherit;
    color: rgba(35,35,35,1);
    text-decoration: none;
    background: 0 0;
    white-space: nowrap;
    text-transform: uppercase;
    line-height: 1.4;
    font-weight: 600;
    position: relative;
    margin: 10px 0;
}

.active .Link:after {
    color: rgb(255, 112, 67);
    width: 100%;
}

.Link {
    display: inline-flex;
    position: relative;
    box-sizing: border-box;
}

.Link:after {
    display: block;
    content: '';
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background: rgb(255, 112, 67);
    transition: width ease-in 0.25s;
}

.Link:hover:after {
    width: 100%;
}

.CopyrightWrapper {
    margin-top: 25px;
    text-transform: uppercase;
}

.CopyrightWrapper,
.TrademarkWrapper {
    font-size: 11px;
    line-height: 1.4;
    box-sizing: border-box;
    vertical-align: baseline;
}

@media (min-width: 480px) {
    .CopyrightWrapper,
    .TrademarkWrapper {
        font-size: 13px;
    }
}

.Legalmark {
    text-transform: inherit;
    text-decoration: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: .7em;
    position: relative;
    top: -.4em;
}